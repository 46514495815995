import './sass/login.sass';
import amplitude from "./components/amplitude";
import { showHidePasswordInput } from "./components/show-hide-password-input";

const emailSubmit = document.getElementById('email-submit');
const backBtn = document.getElementById('back-button');
const emailPassText = document.getElementById('email-pass-text');
const emailField = document.getElementById('email');
const passwordField = document.getElementById('password');
const errorText = document.getElementById('login__error-text');
const emailText = document.getElementById('emailText');
const passwordText = document.getElementById('passwordText');
const loader = document.getElementById('emailLoader');
const submitErrorText = document.getElementById('submit-error-text');

let userId;
let emailChecked = false;

const showValidEmailIcon = () => document.querySelector('.login__field-input-valid-email-img').style.setProperty('display', 'block');

showHidePasswordInput(document.querySelectorAll('.login__field-input-toggle-button'));

backBtn?.addEventListener('click', () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
});

const showError = (message) => {
    emailText.classList.add('login__label-error');
    passwordText.classList.add('login__label-error');
    emailField.classList.add('login__email_form-input-error');
    passwordField.classList.add('login__email_form-input-error');
    errorText.innerText = message;
    submitErrorText.classList.add('active');
    hideLoader();
};

const hideErrors = () => {
    emailText.classList.remove('login__label-error');
    passwordText.classList.remove('login__label-error');
    emailField.classList.remove('login__email_form-input-error');
    passwordField.classList.remove('login__email_form-input-error');
    submitErrorText.classList.remove('active');
    errorText.innerText = '';
};

const redirectToLms = (email) => {
    amplitude.logEvent('Redirect from login to LMS', { email });

    window.location.href = `/lms-redirect/${email}`;
};

const getUserData = () => {
    const url = '/api/v1/user/me';

    return new Promise((resolve, reject) => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => resolve(data))
            .catch((err) => reject(err));
    });
};

const checkEmail = (email) => {
    const url = `/api/v1/user/check-email/${email}`;

    return new Promise((resolve, reject) => {
        fetch(url)
            .then((checkEmailResponse) => checkEmailResponse.json())
            .then((data) => {
                if (data === null || !('status' in data)) {
                    showError('Something went wrong');
                    throw new Error('Password check response incorrect');
                }

                if (data.status === 'error') {
                    showError(data.message);
                    throw new Error('Password check response has error');
                }

                resolve(data.has_password);
            })
            .catch((err) => reject(err));
    });
};

const checkPassword = (password, email) => {
    const url = `/api/v1/user/check-password`;

    const requestOptions = {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email, password }),
    };

    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then((checkPasswordResponse) => checkPasswordResponse.json())
            .then((data) => {
                if (data === null || !('status' in data)) {
                    showError('Something went wrong');
                    throw new Error('Password check response incorrect');
                }

                if (data.status === 'error') {
                    showError(data.message);
                    throw new Error('Password check response has error');
                }

                resolve(true);
            })
            .catch((err) => reject(err));
    });
};

const showLoader = () => {
    loader.style.setProperty('display', 'flex');
    emailSubmit.style.setProperty('display', 'none');
};

const hideLoader = () => {
    emailSubmit.style.setProperty('display', 'flex');
    loader.style.setProperty('display', 'none');
};

const showPasswordField = () => {
    const passwordFieldContainer = document.getElementById('passwordFieldContainer');
    passwordFieldContainer.classList.remove('login__email_form-hidden');
};

if (emailField) {
    emailField.addEventListener("keypress", (event) => {
        if (event.key === "Enter") {
            emailSubmit.click();
        }
    });

    emailField.addEventListener("change", () => {
        hideErrors();
    });
    emailField.addEventListener("change", () => {
        hideErrors();
    });

    emailSubmit.addEventListener('click', () => {
        const email = emailField && emailField.value.trim();
        showLoader();

        try {
            if (email === '') {
                hideLoader();
                showError('Enter valid email');

                setTimeout(() => {
                    hideErrors();
                }, 3000);
            } else if (!emailChecked) {
                hideLoader();

                let hasPassword = true;
                checkEmail(email)
                    .then((password) => {
                        emailChecked = true;
                        hasPassword = password;
                        return getUserData();
                    })
                    .then((userData) => {
                        userId = userData.external_id;
                        if (!hasPassword) {
                            redirectToLms(email);
                        } else {
                            emailPassText.innerHTML = 'password';

                            hideErrors();
                            showValidEmailIcon();
                            showPasswordField();
                        }
                    });
            } else {
                const password = passwordField && passwordField.value;

                checkPassword(password, email, userId)
                    .then(() => {
                        hideErrors();
                        redirectToLms(email);
                    });
            }
        } catch (e) {
            console.error(e);
            amplitude.logEvent('Login failed', { email });
        }
    });
}
