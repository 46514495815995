// eslint-disable-next-line no-unused-vars
function showHidePasswordInput(selectors) {
    selectors.forEach((button) => {
        button.addEventListener('click', (event) => {
            // eslint-disable-next-line no-shadow
            const passwordInput = event.currentTarget.previousElementSibling;

            if (passwordInput.type === "password") {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        });
    });
}

export { showHidePasswordInput };
